import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { HamburgerIcon } from '../../icons/Icons';
import useStyles from '../Ui-styles';
import clsx from 'clsx';

export const MenuButton = (props) => {
  const classes = useStyles();
  const { setMenuOpen } = props;
  return (
    <Button
      onClick={setMenuOpen}
      sx={{
        padding: '0px',
      }}
    >
      <Box display={'flex'} alignItems={'center'} fontWeight={'800'}>
        <HamburgerIcon fill={'white'} />
        <Typography className={clsx(classes.menuButton)}>Menü</Typography>
      </Box>
    </Button>
  );
};
