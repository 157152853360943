import { CloseSharp } from '@mui/icons-material';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  ContactIcon,
  DatenschutzIcon,
  FunktionIcon,
  HeadsetIcon,
  Impressumicon,
  KoperationIcon,
  MitmachenIcon,
  Muticon,
} from '../../icons/Icons';
import { MutAtlasLogo } from '../../icons/logos';
import { Colours } from '../../theme';
import { SocialLinks } from '../common/SocialLinks';
import useStyles from './menuStyles';

export const menuList = [
  {
    title: 'Hilfe bei Krisen',
    icon: <HeadsetIcon />,
    fileNameMD: 'akute-krise',
  },
  {
    title: 'Über den MUT-ATLAS',
    fileNameMD: 'submenu1',
    icon: <Muticon />,
    subMenu: [
      { title: 'Was ist der MUT-ATLAS?', fileNameMD: 'was-ist-der-ma' },
      {
        title: 'Wie funktioniert der MUT-ATLAS?',
        fileNameMD: 'angebote-suchen',
      },
      {
        title: 'Verfügbarkeit der Hilfsangebote',
        fileNameMD: 'verfuegbarkeit',
      },
      { title: 'Unsere App', fileNameMD: 'app' },
      { title: 'Aktualisierungshistorie', fileNameMD: 'versionshistorie' },
      {
        title: 'Wie finanziert sich der MUT-ATLAS?',
        fileNameMD: 'wie-finanziert-sich-der-ma',
      },
    ],
  },
  {
    title: 'Die Angebote im MUT-ATLAS',
    fileNameMD: 'submenu2',
    icon: <FunktionIcon />,
    subMenu: [
      { title: 'Ursprung der Angebote', fileNameMD: 'angebote-ursprung' },
      { title: 'Richtlinien für Einträge', fileNameMD: 'angebote-richtlinien' },
      { title: 'Aktualität', fileNameMD: 'aktualitaet-der-angebote' },
      {
        title: 'Eigene Angebote hinzufügen',
        fileNameMD: 'angebote-hinzufuegen',
      },
      { title: 'Kooperationen', fileNameMD: 'kooperationen' },
    ],
  },
  {
    title: 'So kannst du mitmachen',
    fileNameMD: 'submenu3',
    icon: <KoperationIcon />,
    subMenu: [
      { title: 'MUT-SCOUT werden', fileNameMD: 'mut-scouts' },
      { title: 'Mitglied werden', fileNameMD: 'mitglied-werden' },
    ],
  },
  {
    title: 'Presseinformationen',
    icon: <MitmachenIcon />,
    fileNameMD: 'presseinformationen',
  },
  {
    title: 'Kontakt',
    icon: <ContactIcon />,
    fileNameMD: 'kontakt',
  },
  { title: 'Impressum', icon: <Impressumicon />, fileNameMD: 'impressum' },
  {
    title: 'Datenschutz',
    icon: <DatenschutzIcon />,
    fileNameMD: 'datenschutz',
  },
];

export const Menu = (props) => {
  const { menuDrawer, navLink } = useStyles();
  const history = useHistory();
  const {
    menuState: { menuOpen },
    setMenuState,
  } = props;

  const [activeMenu, setActiveMenu] = React.useState({});

  return (
    <Drawer
      className={menuDrawer}
      variant='persistent'
      anchor='left'
      open={menuOpen}
      style={{
        display: menuOpen ? 'block' : 'none',
      }}
      data-testid='menu'
    >
      <Box display={'flex'} justifyContent={'space-between'} p={2} pt={4}>
        <Box>
          <MutAtlasLogo fill={Colours._darkGreen} />
        </Box>
        <Box>
          <IconButton
            onClick={() => setMenuState({ menuOpen: false })}
            data-testid={'menu-close'}
            size='large'
          >
            <CloseSharp />
          </IconButton>
        </Box>
      </Box>
      <List data-testid='menu-items'>
        {menuList.map(({ title, icon, fileNameMD, subMenu }) => (
          <Fragment key={title}>
            <Box mt={2}>
              <ListItemButton
                onClick={(e) => {
                  e.stopPropagation();

                  if (subMenu && subMenu.length > 0) {
                    setActiveMenu({
                      [fileNameMD]: activeMenu[fileNameMD] ? false : true,
                    });
                    return;
                  } else {
                    history.push(`home/${fileNameMD}`);
                  }
                }}
              >
                <Box
                  display={'flex'}
                  direction='row'
                  justifyContent='center'
                  spacing={8}
                  alignItems='center'
                  pt={1}
                >
                  <Box mr={2} width={20}>
                    {icon}
                  </Box>
                  <ListItemText primary={title} />
                </Box>
              </ListItemButton>

              {activeMenu[fileNameMD]
                ? subMenu.map(({ title, fileNameMD }, i) => (
                    <Box
                      display={'flex'}
                      direction='column'
                      spacing={8}
                      alignItems='center'
                      ml={3}
                      pt={1}
                      pl={3}
                      borderLeft={1}
                      borderColor={'#EBE6E6'}
                      key={`${title}-${i}`}
                    >
                      <Link to={`home/${fileNameMD}`} className={navLink}>
                        <ListItemButton>
                          <ListItemText primary={title} />
                        </ListItemButton>
                      </Link>
                    </Box>
                  ))
                : null}
            </Box>
          </Fragment>
        ))}
      </List>
      <Box p={2} mt='auto'>
        <SocialLinks height={24} width={24} />
      </Box>
    </Drawer>
  );
};
