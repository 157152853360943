import L from 'leaflet';

const icon = {
  iconUrl: null,
  iconAnchor: [17, 20], // point of the icon which will correspond to marker's location
  shadowUrl: '',
  popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
};

export function createYellowIcon(features, latlng) {
  icon.iconUrl =
    process.env.REACT_APP_PATH + 'img/house-user-solid-colored.svg';
  icon.iconSize = [35, 35];
  return L.marker(latlng, { icon: L.icon(icon) });
}

let searchActiveSVG =
  '<svg id="searchPin" width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="mePin" clip-path="url(#clip0_0_1)"><path id="Vector" class="ring_outer" d="M28.6 23C34.7 24.4 39 27.4 39 31C39 35.7 31.3 39.6 21.7 39.6C12.1 39.6 4.3 35.7 4.3 31C4.3 27.5 8.5 24.5 14.6 23.1C15.3 23 14.2 21.6 13.3 21.8C5.5 23.4 0 27.2 0 31.7C0 37.7 9.7 42.4 21.7 42.4C33.7 42.4 43.3 37.6 43.3 31.7C43.3 27.1 37.6 23.3 29.6 21.7C28.8 21.5 27.8 22.9 28.6 23.1V23Z" fill="#878787"/><path class="ring_inner" id="Vector_2" d="M26.7 25.8C28.7 26.5 30 27.6 30 28.8C30 31 26.3 32.7 21.7 32.7C17.1 32.7 13.4 31 13.4 28.9C13.4 27.9 14.2 27 15.6 26.3C16.2 26 15.3 24.3 14.6 24.7C11.8 25.7 10 27.4 10 29.3C10 32.5 15.1 35 21.4 35C27.6 35 32.7 32.5 32.7 29.3C32.7 27.3 30.6 25.4 27.3 24.3C26.6 24.2 26.1 25.6 26.6 25.8H26.7Z" fill="#5F5F5F"/><path id="Vector_3" d="M33.9932 14.9766C33.9932 15.8203 33.316 16.4813 32.5486 16.4813H31.104L31.1356 23.9906C31.1356 24.1172 31.1266 24.2438 31.1131 24.3703V25.125C31.1131 26.1609 30.305 27 29.3074 27H28.5851C28.5354 27 28.4858 27 28.4361 26.9953C28.3729 27 28.3097 27 28.2465 27H26.7794H25.6959C24.6983 27 23.8902 26.1609 23.8902 25.125V24V21C23.8902 20.1703 23.2447 19.5 22.4457 19.5H19.5565C18.7575 19.5 18.112 20.1703 18.112 21V24V25.125C18.112 26.1609 17.3039 27 16.3063 27H15.2228H13.7828C13.7151 27 13.6474 26.9953 13.5796 26.9906C13.5255 26.9953 13.4713 27 13.4171 27H12.6948C11.6972 27 10.8891 26.1609 10.8891 25.125V19.875C10.8891 19.8328 10.8891 19.7859 10.8936 19.7438V16.4813H9.44457C8.632 16.4813 8 15.825 8 14.9766C8 14.5547 8.13543 14.1797 8.45143 13.8516L20.026 3.375C20.342 3.04688 20.7032 3 21.0192 3C21.3352 3 21.6963 3.09375 21.9672 3.32813L33.4966 13.8516C33.8578 14.1797 34.0383 14.5547 33.9932 14.9766Z" fill="black"/></g><defs><clipPath id="clip0_0_1"><rect width="43.3" height="42.4" fill="white"/></clipPath></defs></svg>';

export const meIcon = L.divIcon({
  className: 'search-icon',
  html: searchActiveSVG.replace('#', '%23'),

  iconAnchor: [22, 28],
  iconSize: [36, 42],
  popupAnchor: [0, -30],
});

export function createGreenIcon(features, latlng) {
  icon.iconUrl = process.env.REACT_APP_PATH + 'img/house.svg';
  icon.iconSize = [100, 100];

  return L.marker(latlng, {
    icon: meIcon,
    title: 'Suchstandort',
  });
}
