import { Box, Grid } from '@mui/material';
import React, { Fragment } from 'react';
import { KAT_NICE_NAMES } from '../../../lib/helperFunctions';
import { Colours } from '../../../theme';
import { MULTIFIELD_VALUES_TO_SHOW } from '../MapPopup';

export const Categories = ({ groupedCategories }) => {
  const sortedKeys = MULTIFIELD_VALUES_TO_SHOW.filter(
    (key) => key in groupedCategories
  );

  return (
    <Grid container>
      {sortedKeys.map((key) => {
        const category = groupedCategories?.[key];
        return (
          <Fragment key={key}>
            {category.length > 0 ? (
              <Grid
                item
                xs={12}
                sm={5.5}
                style={{
                  border: '1px solid #e0e0e0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  margin: '4px',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.01)',
                }}
              >
                <Box component='h3' mt={0} mb={0.5}>
                  {KAT_NICE_NAMES[key]}
                </Box>
                <Box
                  component='ul'
                  my={0}
                  pl={0}
                  sx={{
                    listStyle: 'none',
                  }}
                >
                  {category.length > 0 &&
                    category.map((item, index) => (
                      <Box
                        key={`${item}-${index}`}
                        display='flex'
                        alignItems='center'
                      >
                        <Box
                          minWidth='4px'
                          height='4px'
                          bgcolor={Colours._textGrey}
                          borderRadius='50%'
                          mr={0.5}
                        />
                        <Box
                          component='li'
                          fontSize='12px'
                          key={index}
                          color={Colours._textGrey}
                          style={{
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word',
                          }}
                        >
                          {item}
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Grid>
            ) : null}
          </Fragment>
        );
      })}
    </Grid>
  );
};
