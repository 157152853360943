import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  topContainer: {
    gap: '.2rem',
    '& a': {
      textDecoration: 'none',
      display: 'inline',
    },
  },
  container: {
    display: 'flex',
    marginTop: '4px',
  },
  nameContainer: {
    gap: '.5rem',
    color: 'var(--dark-green)',
    '& a': {
      textDecoration: 'none',
      color: 'var(--dark-green)',
    },
  },
  text: {
    margin: '0 !important',
    '& a': {
      color: 'var(--dark-green)',
      textDecoration: 'underline',
    },
  },
  strong: {
    textDecoration: 'capitalize',
    color: 'var(--dark-green)',
  },
  name: {
    fontWeight: '600',
    fontSize: '1.2rem',
    padding: '0.25rem 1rem .0rem 0',
    color: 'var(--dark-green)',
  },

  span: {
    fontSize: '.7rem',
    color: 'var(--dark-green)',
    '&:hover': {
      background: 'transparent',
    },
  },
});
