import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';
import { Colours } from '../../theme';

export const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: Colours._textGrey,
    '&.Mui-selected': {
      color: Colours._darkGreen,
    },
    '&.Mui-focusVisible': {
      backgroundColor: Colours._darkGreen,
    },
  })
);

export const CustomTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))({
  '& .MuiTabs-scroller': {
    borderBottom: '1px solid #e8e8e8',
    marginBottom: '12px',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: Colours._darkGreen,
  },
});
