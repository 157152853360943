import React, { useEffect, useRef, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useSearchParams } from '../../hooks/useSearchParams';
import {
  CATEGORIES_KEY_MAP,
  FILTER_CATEGORIES_NAMES_MAP,
} from '../../lib/constants';
import { styles } from './FilterComponent-styles';

const SubFiltersImpl = (props) => {
  const {
    classes,
    row,
    allCategoriesGrouped,
    selectedFilterSearchData,
    checked,
    handleCheck: handleCheckProp,
  } = props;

  const accordionRefs = useRef({});
  const { searchParams } = useSearchParams();
  const history = useHistory();
  const location = useLocation();
  const [accordionState, setAccordionState] = useState({
    expanded: {},
    activeAccordion: null,
    shouldScroll: false,
  });

  const { activeAccordion, shouldScroll } = accordionState;

  const handleCheck = (title) => {
    const newChecked = { ...checked };
    const isCurrentlyChecked = checked[title];

    newChecked[title] = !isCurrentlyChecked;

    handleCheckProp(newChecked);

    const checkedKeys = Object.keys(newChecked).filter(
      (key) => newChecked[key]
    );

    searchParams.set('filters', checkedKeys.join());

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    if (
      activeAccordion &&
      accordionRefs.current[activeAccordion] &&
      shouldScroll
    ) {
      const currentRef = accordionRefs.current[activeAccordion].current;
      if (currentRef) {
        //scroll the filter items of the accordion into view
        currentRef.scrollIntoView({
          behavior: 'smooth',
        });
      }
      setAccordionState((prev) => ({ ...prev, shouldScroll: false }));
    }
  }, [activeAccordion, shouldScroll]);

  const desiredOrder = Object.keys(FILTER_CATEGORIES_NAMES_MAP);

  return desiredOrder.map((group) => {
    if (group !== 'Kat_Was' && group !== 'Kat_WasDetail') {
      if (!allCategoriesGrouped[group]) {
        return null;
      }
      const sortedbyAnmerkung = allCategoriesGrouped[group].sort((a, b) =>
        a.anmerkung.localeCompare(b.anmerkung)
      );

      if (!accordionRefs.current[group]) {
        accordionRefs.current[group] = React.createRef();
      }

      return (
        <FormGroup
          classes={row !== 'no' ? { root: classes.optGrp } : { root: 'foo' }}
          key={group}
        >
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            sx={{
              boxShadow: 'none',
              minHeight: 'unset',
            }}
            className={classes.accordion}
            expanded={accordionState.expanded[group] ?? false}
            onChange={() => {
              setAccordionState((prev) => ({
                ...prev,
                activeAccordion: group,
                shouldScroll: true,
                expanded: {
                  ...prev.expanded,
                  [group]: !prev.expanded[group],
                },
              }));
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${group}-content`}
              id={`${group}-header`}
              sx={{
                paddingX: '8px',
                paddingLeft: '12px',
                margin: '0',
              }}
              ref={accordionRefs.current[group]}
            >
              <Typography
                className={classes.optHeading}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>{FILTER_CATEGORIES_NAMES_MAP[group]}</span>

                {/* when constructing the payload (selectedFilterSearchData) we use what is expected for the API but a bit differemt from 
                original data structure fetched from filter categories (group)
                */}
                {selectedFilterSearchData?.[CATEGORIES_KEY_MAP?.[group]]
                  ?.length ? (
                  <Box component='span' display='flex' alignItems='center'>
                    <CheckIcon
                      fontSize='small'
                      sx={{
                        marginTop: '-4px',
                        marginLeft: '8px',
                      }}
                    />
                    <span>
                      {`(${
                        selectedFilterSearchData?.[CATEGORIES_KEY_MAP?.[group]]
                          .length
                      })`}
                    </span>
                  </Box>
                ) : null}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingX: 2,
              }}
            >
              {sortedbyAnmerkung.map((option) => (
                <FormControlLabel
                  label={option.wert}
                  key={option.wert}
                  className={`${option.iconClass} ${classes.form} ${classes.optLabel}`}
                  control={
                    <Checkbox
                      checked={checked[option.wert] ?? false}
                      onChange={() => {
                        handleCheck(option.wert);
                      }}
                      name={option.wert}
                    />
                  }
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </FormGroup>
      );
    }
    return null;
  });
};

const SubFilters = withStyles(styles)(SubFiltersImpl);

export { SubFilters };
