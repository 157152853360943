import { AppBar, Box, Divider, Toolbar } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import useStyles from '../../components/Ui-styles';
import { MutAtlasLogo, MutLogo } from '../../icons/logos';
import { Link } from 'react-router-dom';
import { Colours } from '../../theme';
import Markdown from 'markdown-to-jsx';
import ScrollTopButton from '../../components/common/ScrollTopButton';

export const Info = () => {
  const { toolbar, appBar, logoMob } = useStyles();
  const [markdownState, setMarkdownState] = React.useState({
    isLoading: false,
    error: '',
    markdownContent: [],
  });

  const getMarkdown = useCallback(async () => {
    setMarkdownState({
      isLoading: true,
    });
    try {
      // Has to be hardcoded because of the way the markdown is imported
      // not accessed from the filesystem
      const markdowns = [
        'was_ist_der_ma',
        'akute_krise',
        'aktualitaet_der_angebote',
        'angebote_hinzufuegen',
        'wie_finanziert_sich_der_ma',
        'kooperationen',
        'angebote_suchen',
        'mitglied_werden',
        /* 'datenschutz',
        'impressum',
        'kontakt', */
      ];

      const markdownContent = [];

      for (const markdownPath of markdowns) {
        const res = await import(
          `../../components/staticPages/${markdownPath}.md`
        );

        const response = await fetch(res.default);
        const content = await response.text();
        markdownContent.push(content);
      }

      setMarkdownState({
        isLoading: false,
        markdownContent,
      });
      return;
    } catch (error) {
      setMarkdownState({
        isLoading: false,
        error: `Content not found: ${error.message}`,
      });
      return [];
    }
  }, []);

  useEffect(() => {
    getMarkdown();
  }, [getMarkdown]);

  const navBar = () => {
    return (
      <AppBar position='fixed' className={clsx(appBar)}>
        <Toolbar className={clsx(toolbar)}>
          <Link to='/home'>
            <MutAtlasLogo fill={'#fff'} />
          </Link>

          <div className={logoMob}>
            <a
              href='https://www.mut-foerdern.de'
              target='_blank'
              rel='noopener noreferrer'
            >
              <MutLogo fill='white' />
            </a>
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  if (markdownState.isLoading) {
    return (
      <div>
        {navBar()}
        <Box
          px={2}
          display='flex'
          flexDirection='column'
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          minHeight={'calc(100vh - 64px)'}
        >
          <MutAtlasLogo fill={Colours._darkGreen} />
          <div>Wird geladen...</div>
        </Box>
      </div>
    );
  }

  if (markdownState.error) {
    return (
      <div>
        {navBar()}
        <Box
          px={2}
          display='flex'
          flexDirection='column'
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          minHeight={'calc(100vh - 64px)'}
        >
          <MutAtlasLogo fill={Colours._darkGreen} />
          <div>{markdownState.error}</div>
        </Box>
      </div>
    );
  }

  return (
    <div>
      {navBar()}

      <Box mt={12} px={4} id='info'>
        {markdownState.markdownContent.length > 0 ? (
          markdownState.markdownContent.map((content, index) => {
            return (
              <Box key={`${index}-${content.substring(0, 5)}`} my={4}>
                <Markdown>{content}</Markdown>
                <Divider />
              </Box>
            );
          })
        ) : (
          <Box
            px={2}
            display='flex'
            flexDirection='column'
            gap={2}
            justifyContent={'center'}
            alignItems={'center'}
            minHeight={'calc(100vh - 64px)'}
          >
            <MutAtlasLogo fill={Colours._darkGreen} />
            <Box>Keine Inhalte gefunden</Box>
          </Box>
        )}
      </Box>

      <ScrollTopButton />
    </div>
  );
};
