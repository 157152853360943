import { Alert, Box, Grid, Typography, useMediaQuery } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useReducer } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useMatomo } from 'matomo-react';
import {
  DirectionIcon,
  ExternalLinkIcon,
  LocationIcon,
} from '../../icons/Icons';
import { categoryDetailsMapping } from '../../lib/helperFunctions';
import { sourceLinks } from '../../lib/sourceLinks';
import { Colours } from '../../theme';
import { CustomTab, CustomTabs } from '../common/CustomTab';
import { CustomButton } from '../common/PopupButton';
import { TabPanel } from '../common/TabPanel';
import { AvailabilityBadge } from './AvailabilityBadge';
import { ErrorReporting } from './ErrorReporting';
import { GoToDirection } from './GoToDirection';
import { PopupError } from './PopupError';
import { useStyles } from './PopupStyles';
import { Categories } from './tabs/Categories';
import { General } from './tabs/General';
import { OpeningHours } from './tabs/OpeningHours';

const ANGEBOTE_BASE = `${process.env.REACT_APP_API_URL}/angebote/`;

export const MULTIFIELD_VALUES_TO_SHOW = [
  'Kat_Was',
  'Kat_WasDetail',
  'Kat_Zielgruppe',
  'Kat_Konstellation',
  'Kat_Kosten',
  'Kat_Ort',
  'Kat_Themen',
  'Kat_Barriere',
  'Kat_Sprache',
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const MapPopup = (props) => {
  const { trackEvent } = useMatomo();

  const [popUp, setPopUp] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      data: null,
      traeger: null,
      status: null,
      reportError: false,
      goToDirection: false,
      tabValue: 0,
    }
  );

  const { topContainer, nameContainer, name } = useStyles();
  const isDesktop = useMediaQuery('(min-width:600px)');

  const { data: popupData, bindPopup, layer, history } = props;

  const handleChange = (event, newValue) => {
    setPopUp({ tabValue: newValue });
    bindPopup && bindPopup();
  };
  const {
    properties: { searchResult },
  } = popupData;

  layer &&
    layer.on('popupclose', () => {
      setPopUp({ reportError: false });
    });

  const url = `${ANGEBOTE_BASE}angebot/${popupData.properties.guid}`;

  const getPopupdata = useCallback(async () => {
    setPopUp({ loadingAngebote: true });
    try {
      const resp = await axios.get(url);

      let json = JSON.stringify(resp.data);

      //eslint-disable-next-line
      json.replace(/[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/g, '');

      const popUpData = JSON.parse(json);

      let tragerData = null;

      setPopUp({
        data: popUpData,
      });

      // Get traeger data
      if (popUpData.refLfNrTraeger) {
        const getTraegerUrl = `${ANGEBOTE_BASE}gettraegerfromlfnr/${popUpData.refLfNrTraeger}`;

        try {
          const { data } = await axios.get(getTraegerUrl);
          tragerData = data;

          setPopUp({
            traeger: {
              ort: tragerData.ort,
              firma: tragerData.firma,
              homePage: tragerData.homePage,
            },
          });
        } catch (error) {
          console.error(`Error fetching related data: ${error}`);
        }
      }
    } catch (error) {
      setPopUp({ status: error });
    }
  }, [url]);

  useEffect(() => {
    getPopupdata();
  }, [getPopupdata]);

  const { data, reportError, status, goToDirection, tabValue } = popUp || {};

  const {
    einsAktiv,
    katWasSum,
    mfAngebotMultifieldValues,
    strasse,
    hausnummer,
    landkreis,
    plz,
    ort,
  } = data || {};
  /* TODO */
  /*   const directionUrl = useGetUrl(latitude, longitude); */

  const addressFragments = { strasse, hausnummer, landkreis, plz, ort };
  const fullAddress = `${strasse} ${hausnummer}, ${plz} ${ort}, ${landkreis}`;
  const encodedAddress = encodeURIComponent(fullAddress);

  const categories = katWasSum ? katWasSum.split(',') : [];

  const multiFieldCategories = mfAngebotMultifieldValues?.filter((mf) =>
    MULTIFIELD_VALUES_TO_SHOW.includes(mf?.fmMultiField?.name)
  );

  const groupedByName = () => {
    const grouped = {};

    const groupByName = () =>
      multiFieldCategories?.map((mf) => {
        if (!grouped[mf.fmMultiField.name]) {
          grouped[mf.fmMultiField.name] = [];
        }
        grouped[mf.fmMultiField.name].push(mf.fmMultiFieldValue.value);
        return null;
      });
    groupByName();

    return grouped;
  };

  const istakingNewClients = data?.neueKlientenApi?.toLowerCase();
  const renderPopup = () => {
    if (data) {
      const shouldShowOpeningHoursTabs = popUp.data.openingHours.length > 0;
      return (
        <Box
          display={'flex'}
          flexDirection={'column'}
          className={topContainer}
          pb={1}
        >
          {!einsAktiv && searchResult ? (
            <Alert
              severity={'warning'}
              sx={{
                marginTop: '8px',
                '.MuiAlert-icon ': {
                  paddingTop: '10px',
                },
              }}
            >
              Dieses Angebot wurde im MUT-ATLAS deaktiviert. Möglicherweise
              existiert es nicht mehr.
            </Alert>
          ) : null}

          {data.name ? (
            <Box display='flex' alignItems='center' className={nameContainer}>
              <Box minWidth={'22px'} alignItems='center'>
                <LocationIcon width='22px' />
              </Box>
              <Typography
                variant='h5'
                className={name}
                sx={{
                  fontWeight: '600',
                  fontSize: '1.2rem',
                }}
              >
                {data.name}
              </Typography>
            </Box>
          ) : null}

          {istakingNewClients !== 'unbekannt' && istakingNewClients !== '' && (
            <AvailabilityBadge
              istakingNewClients={istakingNewClients}
              history={history}
            />
          )}

          <Grid
            container
            style={{
              margin: '8px 0',
              marginBottom: '-4px',
            }}
          >
            {categories.length > 0
              ? categories.map((category, index) => {
                  const icon = categoryDetailsMapping?.[category]?.icon;
                  return (
                    <Grid
                      item
                      xs={'auto'}
                      key={index}
                      sx={{
                        display: 'flex',
                        borderradius: '24px',
                        gap: '.2rem',
                        alignItems: 'center',
                        border: `1px solid ${Colours._lightGrey}`,
                        padding: '2px 8px',
                        marginRight: '4px',
                      }}
                    >
                      {icon && (
                        <Box display='flex' className={'category-icon'}>
                          {icon}
                        </Box>
                      )}
                      <Box fontSize={'.7rem'} display='inline' my='1px'>
                        {category}
                      </Box>
                    </Grid>
                  );
                })
              : null}
          </Grid>

          {!reportError ? (
            <CustomTabs
              value={tabValue}
              onChange={handleChange}
              aria-label='angebote tabs'
            >
              <CustomTab label='Allgemein' {...a11yProps(0)} />
              {shouldShowOpeningHoursTabs && (
                <CustomTab label='Öffnungszeiten' {...a11yProps(1)} />
              )}
              {Object.keys(groupedByName()).length > 0 ? (
                <CustomTab
                  label='Schlagworte'
                  {...a11yProps(shouldShowOpeningHoursTabs ? 2 : 1)}
                />
              ) : null}
            </CustomTabs>
          ) : null}

          {!reportError ? (
            <>
              <TabPanel value={tabValue} index={0}>
                <General data={props} popUp={popUp} setPopUp={setPopUp} />
              </TabPanel>
              {shouldShowOpeningHoursTabs ? (
                <TabPanel value={tabValue} index={1}>
                  <OpeningHours data={popUp.data} />
                </TabPanel>
              ) : null}

              {Object.keys(groupedByName()).length > 0 ? (
                <TabPanel
                  value={tabValue}
                  index={shouldShowOpeningHoursTabs ? 2 : 1}
                >
                  <Categories groupedCategories={groupedByName()} />
                </TabPanel>
              ) : null}
            </>
          ) : null}
        </Box>
      );
    } else {
      return <PopupError status={status} />;
    }
  };

  if (goToDirection) {
    return (
      <GoToDirection
        addressFragments={addressFragments}
        backToAngebote={() => setPopUp({ goToDirection: false })}
      />
    );
  }

  const { url: quelle_url, name: quelle_name } = sourceLinks(data?.quelle);

  return (
    <Box display={'flex'} flexDirection={'column'} p={1}>
      {renderPopup()}
      {reportError ? (
        <ErrorReporting
          listingAutoLfNr={data.autoLfNr}
          guid={popupData.properties.guid}
          closeForm={() => setPopUp({ reportError: false })}
        />
      ) : (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          flexWrap='wrap'
          gap={1}
        >
          <CustomButton
            style={{
              width: !isDesktop ? '100%' : 'fit-content',
            }}
            onClick={(e) => {
              // Matomo event
              // see https://github.com/maxfahl/matomo-react
              trackEvent({
                category: 'errorReporting',
                action: 'reportButtonClicked',
              });

              e.stopPropagation();
              isDesktop && bindPopup && bindPopup();
              setPopUp({
                reportError: true,
              });
            }}
          >
            <EditOutlinedIcon />
            <Box component={'span'} mr={4}>
              Fehler melden
            </Box>
          </CustomButton>

          {!isDesktop ? (
            <CustomButton
              onClick={(e) => {
                e.stopPropagation();
                setPopUp({
                  goToDirection: true,
                });
              }}
              style={{
                width: '100%',
              }}
            >
              <Box component={'span'} mr={4}>
                Führe mich hin
              </Box>

              <DirectionIcon />
            </CustomButton>
          ) : (
            <a
              className='fuhrer-link'
              target='_blank'
              rel='noopener noreferrer'
              href={`https://www.openstreetmap.org/search?query=${encodedAddress}`}
            >
              <CustomButton
                style={{
                  width: 'fit-content',
                }}
              >
                <Box component={'span'} mr={4}>
                  Führe mich hin
                </Box>
                <DirectionIcon />
              </CustomButton>
            </a>
          )}
        </Box>
      )}
      {data?.validierung === 'unvalidiert' ? (
        <Box
          display={'flex'}
          alignItems={'center'}
          className={nameContainer}
          mt={2}
          mb={1}
        >
          <Box width={'16px'} mr={1}>
            <InfoOutlined />
          </Box>
          <Typography variant='body3'>
            Es handelt sich um ein noch nicht validiertes Angebot, das
            möglicherweise ungültige Informationen enthält.
          </Typography>
        </Box>
      ) : null}

      <Box
        display={'flex'}
        alignItems={'center'}
        mt={2}
        mb={1}
        className={nameContainer}
      >
        <Box component='span' fontStyle={'italic'} color={'#666'}>
          Quelle:
        </Box>

        {((!data?.quelle || data?.quelle.toLowerCase() === 'bvk') && (
          <Typography variant='body3' fontWeight={600}>
            MUT-ATLAS
          </Typography>
        )) || (
          <>
            <a href={quelle_url} target='_blank' rel='noopener noreferrer'>
              <Box
                component='span'
                gap={0.5}
                display='flex'
                alignItems='center'
              >
                <Typography variant='body3'>{quelle_name}</Typography>
                <ExternalLinkIcon />
              </Box>
            </a>
          </>
        )}
      </Box>
    </Box>
  );
};
