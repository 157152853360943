export const FILTER_CATEGORIES_NAMES_MAP = {
  /* category name in "Werte"-Endpoint :  description in the Frontend */
  Kat_Was: 'Einrichtungen',
  Kat_Zielgruppe: 'Zielgruppe',
  Kat_Geschlechter: 'Geschlecht',
  // Kat_Häufigkeit: 'Häufigkeit',
  Kat_Konstellation: 'Konstellation',
  Kat_Kosten: 'Kostenübernahmen',
  Kat_PersonAlter: 'Alter',
  Kat_PersonRolleDetail: 'Personen Rollen Details',
  Kat_PersonRollen: 'Rolle',
  Kat_Ort: 'Orte',
  Kat_Themen: 'Themen',
  Kat_Barriere: 'Zugänglichkeit',
  Sprache: 'Sprache',
};

// The two APIs angebotedoc/werte and angebotedoc/filter have different names for the categories:
export const CATEGORIES_KEY_MAP = {
  /* category name in "Werte"-Endpoint :  category name in "filter"-Endpoint */
  Kat_Was: 'kat_Was',
  Kat_Zielgruppe: 'kat_Zielgruppe',
  Kat_Geschlechter: 'kat_Geschlechter',
  Kat_Konstellation: 'kat_Konstellationen',
  Kat_Kosten: 'kat_Kosten',
  Kat_PersonAlter: 'kat_PersonAlter',
  Kat_PersonRolleDetail: 'kat_PersonRolleDetail',
  Kat_PersonRollen: 'kat_PersonRollen',
  Kat_Ort: 'kat_Orte',
  Kat_Themen: 'kat_Themen',
  Kat_Barriere: 'kat_Barrieren',
  Kat_WasDetail: 'kat_WasDetails',
  Sprache: 'kat_Sprachen',
};

export const DEFAULT_ZOOM = 12;
