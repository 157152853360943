import { Box, Button, Tooltip } from '@mui/material';

import React from 'react';
import { Colours } from '../../theme';
import { Info } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

export const AvailabilityBadge = ({ istakingNewClients, history }) => {
  const {
    _lightYellow,
    _lightOrange,
    _darkYellow,
    _orange,
    _darkGreen,
    _lightGrey,
  } = Colours;

  const bgColor = () => {
    if (istakingNewClients === 'ja') {
      return _darkGreen;
    } else if (istakingNewClients === 'nein') {
      return _lightOrange;
    } else {
      return _lightYellow;
    }
  };

  const useAvailabilityStyles = makeStyles({
    button: {
      '&.MuiButtonBase-root': {
        '&:hover': {
          backgroundColor: bgColor(),
        },
      },
    },
  });

  const { button } = useAvailabilityStyles();

  const color = () => {
    if (istakingNewClients === 'ja') {
      return _lightGrey;
    } else if (istakingNewClients === 'nein') {
      return _orange;
    } else {
      return _darkYellow;
    }
  };

  const takingClients = ({ showShortForm }) => {
    if (istakingNewClients === 'ja') {
      return showShortForm
        ? 'Verfügbar'
        : 'Dieses Angebot nimmt derzeit neue Klient*innen auf.';
    } else if (istakingNewClients === 'nein') {
      return showShortForm
        ? 'Nicht verfügbar'
        : 'Dieses Angebot nimmt derzeit keine neuen Menschen auf.';
    } else {
      return showShortForm
        ? 'Verfügbarkeit unbekannt'
        : 'Wir wissen nicht, ob dieses Angebot derzeit neue Menschen aufnehmen kann.';
    }
  };
  return (
    <Button
      className={button}
      borderRadius={24}
      sx={{
        background: bgColor(),
        maxWidth: 'max-content',
        borderRadius: '32px',
        textTransform: 'initial',
        padding: '4px 8px',
      }}
      px={1}
      py={0.5}
      mb={0.5}
      display='flex'
      alignItems='center'
      justifyContent={'space-between'}
      gap={0.6}
      onClick={() => history.push('/home/verfuegbarkeit')}
    >
      <Tooltip title={takingClients({ showShortForm: false })}>
        <Info
          sx={{
            fontSize: '.8rem',
            color: color(),
          }}
        />
      </Tooltip>
      <Box
        component='span'
        display='inline'
        maxWidth='fit-content'
        color={color()}
        fontSize='12px'
        ml={0.1}
      >
        {takingClients({ showShortForm: true })}
      </Box>
    </Button>
  );
};
