import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';

const tableStyle = makeStyles({
  table: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    borderBottom: 'none',
    marginTop: '8px',
    '& > * .MuiTableHead-root ': {
      '& > * .MuiTableCell-root': {
        fontWeight: 'bold',
      },
    },
  },
  headCell: {
    borderRight: '1px solid #ccc',
  },
});

const dayToWeekday = {
  1: 'Montag',
  2: 'Dienstag',
  3: 'Mittwoch',
  4: 'Donnerstag',
  5: 'Freitag',
  6: 'Samstag',
  7: 'Sonntag',
};

export const OpeningHours = ({ data }) => {
  const { table, headCell } = tableStyle();

  const { openingHours, einsGeoeffnetNachVereinbarung } = data;

  const hour = (time) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    };
    return new Date(`1970-01-01T${time}Z`).toLocaleTimeString('en-US', options);
  };

  return (
    <Box>
      {einsGeoeffnetNachVereinbarung ? (
        <Typography variant='body3' color='#8DA8A8'>
          (Zusätzliche) Termine nach Vereinbarung
        </Typography>
      ) : null}
      <TableContainer className={table}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center' className={headCell}>
                Tag
              </TableCell>
              <TableCell align='center' className={headCell}>
                Vormittag
              </TableCell>
              <TableCell align='center'>Nachmittag</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 7 }, (_, i) => i + 1).map((day) => {
              const hasBeforeMiddayOpeningHours = openingHours.some(
                ({ dayOfWeek, open1, close1 }) =>
                  !!open1 && !!close1 && dayOfWeek === day
              );

              const hasAfterMiddayOpeningHours = openingHours.some(
                ({ dayOfWeek, open2, close2 }) =>
                  !!open2 && !!close2 && dayOfWeek === day
              );

              return (
                <TableRow key={day}>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      background: day % 2 === 0 ? '#f5f5f5' : 'white',
                    }}
                    align='center'
                    className={headCell}
                  >
                    {dayToWeekday[day]}
                  </TableCell>
                  {openingHours?.length > 0
                    ? openingHours.map(
                        ({ close1, open1, open2, close2, dayOfWeek }, i) => {
                          const beforeMidday =
                            !!open1 && !!close1 && dayOfWeek === day;
                          const afterMidday =
                            !!open2 && !!close2 && dayOfWeek === day;
                          const hasOneOfOpeningTimes =
                            !!beforeMidday || !!afterMidday;

                          return (
                            <Fragment key={`${day}-${i}`}>
                              {hasOneOfOpeningTimes && (
                                <Fragment key={`${day}-${i}`}>
                                  {!!beforeMidday && (
                                    <TableCell
                                      align={'center'}
                                      colSpan={afterMidday ? 1 : 2}
                                      style={{
                                        background:
                                          day % 2 === 0 ? '#f5f5f5' : 'white',
                                      }}
                                    >
                                      <div>
                                        {hour(open1)} - {hour(close1)}
                                      </div>
                                    </TableCell>
                                  )}
                                  {!!afterMidday && (
                                    <TableCell
                                      align={'center'}
                                      colSpan={beforeMidday ? 1 : 2}
                                      style={{
                                        background:
                                          day % 2 === 0 ? '#f5f5f5' : 'white',
                                      }}
                                    >
                                      <div>
                                        {hour(open2)} - {hour(close2)}
                                      </div>
                                    </TableCell>
                                  )}
                                </Fragment>
                              )}
                            </Fragment>
                          );
                        }
                      )
                    : null}

                  {hasBeforeMiddayOpeningHours ||
                  hasAfterMiddayOpeningHours ? null : (
                    <TableCell
                      align='center'
                      colSpan={2}
                      style={{
                        background: day % 2 === 0 ? '#f5f5f5' : 'white',
                      }}
                    >
                      -
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
