import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useSearchParams() {
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const filters = searchParams.get('filters') || '';
  const filtersArray = filters ? filters.split(',') : [];
  const source = searchParams.get('source') || '';
  const searchTerm = searchParams.get('search') || '';

  return { searchParams, filters, filtersArray, source, searchTerm };
}
