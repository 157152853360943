import { useEffect, useState } from 'react';

export const useDebounce = ({ value, delay, callback }) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
      callback(value);
    }, delay);
    return () => clearTimeout(timer);
  }, [value, delay, callback]);

  return debouncedValue;
};
