import { Box, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { getHostName } from '../../../lib/helperFunctions';
import { useStyles } from '../PopupStyles';

export const General = ({ popUp }) => {
  const { container, text } = useStyles();

  const { data, reportError } = popUp || {};

  const { traeger } = popUp || {};
  const {
    ort: traegerOrt,
    firma,
    homePage,
  } = traeger || { ort: '', firma: '', homePage: '' };

  const getLocationLink = (value, key) => {
    if (key === 'internet') {
      return (
        <a href={value} rel='nofollow noopener noreferrer' target='_blank'>
          {getHostName(value)}
        </a>
      );
    } else if (key === 'email') {
      return <a href={`mailto:${value}`}>{value}</a>;
    } else if (key === 'telefon') {
      return <a href={`tel:${value}`}>{value}</a>;
    }

    return value;
  };

  //Get the right data keys
  const selectedLocationKeys = {
    beschreibung: 'beschreibung',
    zielgruppe: 'zielgruppe',
    öffnungszeiten: 'oeffnungszeiten',
    address: {
      strasse: 'strasse',
      plz: 'plz',
      ort: 'ort',
      hausnummer: 'hausnummer',
    },
    landkreis: 'landkreis',
    telefon: 'telefon',
    email: 'email',
    internet: 'homePage',
  };

  //Create new object with the selected keys
  const newSelectedLocation = Object.entries(selectedLocationKeys).reduce(
    (acc, [newKey, oldKey]) => {
      // if the old key is a string, just copy the value
      if (typeof oldKey === 'string' && data.hasOwnProperty(oldKey)) {
        acc[newKey] = data[oldKey];
      }
      // If the old key is an object, we need to loop through it's keys
      // and copy the values to the new adresse object
      else if (typeof oldKey === 'object') {
        acc[newKey] = Object.entries(oldKey).reduce(
          (address, [subKey, subOldKey]) => {
            if (data.hasOwnProperty(subOldKey)) {
              address[subKey] = data[subOldKey];
            }
            return address;
          },
          {}
        );
      }
      return acc;
    },
    {}
  );

  // check if the Träger-Link has http / https
  if (!!traeger?.homePage) {
    var pattern = /^((http|https):\/\/)/;
    if (!pattern.test(traeger.homePage)) {
      traeger.homePage = 'http://' + traeger.homePage;
    }
  }

  return (
    <>
      {data?.einsGeoeffnetNachVereinbarung ? (
        <Typography variant='body3' color='#8DA8A8' marginBottom={1}>
          (Zusätzliche) Termine nach Vereinbarung
        </Typography>
      ) : null}

      {Object.keys(newSelectedLocation).map((key) => {
        const locationValue = newSelectedLocation[key];
        const { strasse, plz, ort, hausnummer } = locationValue || {};

        if (locationValue && !reportError) {
          if (key === 'address') {
            return (
              <Fragment key={key}>
                {strasse || hausnummer || ort || plz ? (
                  <Box className={container}>
                    <Typography className={text}>
                      <strong>Adresse: </strong>
                      {strasse || hausnummer
                        ? `${strasse} ${hausnummer}, `
                        : null}
                      {plz} {ort}
                    </Typography>
                  </Box>
                ) : null}
              </Fragment>
            );
          } else
            return (
              <Box className={container} key={key}>
                <Typography className={text}>
                  <strong className={'strong'}>{key}: </strong>
                  {getLocationLink(locationValue, key)}
                </Typography>
              </Box>
            );
        }
        return null;
      })}
      {!reportError && (!!traegerOrt || !!firma) ? (
        <Box className={container}>
          <Typography className={text}>
            <strong>Anbieter: </strong>
            {firma ? (
              <Typography
                component={!!homePage ? 'a' : 'span'}
                href={traeger?.homePage}
                target='_blanck'
              >
                {firma}
              </Typography>
            ) : null}
            {traegerOrt ? `, ${traegerOrt}` : null}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};
