import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowRight as ArrowIcon,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import React, { Suspense, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useSearchParams } from '../../hooks/useSearchParams';
import { categoryDetailsMapping } from '../../lib/helperFunctions';
import { Colours } from '../../theme';

export const Tooltip = React.lazy(() => import('./Tooltip'));

// Custom styled checkbox
const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    color: Colours._darkGreen,
    maxWidth: '16px',
    maxHeight: '16px',
  },

  '&.MuiSvgIcon-root': {
    fontSize: '.8rem',
  },

  '&.Mui-checked': {
    color: Colours._darkGreen,
  },
  '&.MuiCheckbox-indeterminate': {
    color: Colours._darkGreen,
  },
}));

export function MainFilterTree({
  data,
  checked,
  handleCheck: handleCheckProp,
}) {
  const history = useHistory();
  const location = useLocation();

  const { searchParams, filtersArray } = useSearchParams();

  const initialFilters = filtersArray.reduce((acc, filter) => {
    acc[filter] = true;
    return acc;
  }, {});

  const [expanded, setExpanded] = useState(initialFilters);

  const sortedEntries = Object.entries(data).sort(([keyA], [keyB]) => {
    const posA = categoryDetailsMapping[keyA]?.position || Infinity;
    const posB = categoryDetailsMapping[keyB]?.position || Infinity;
    return posA - posB;
  });

  const handleToggle = (nodeId) => {
    setExpanded((prev) => ({
      ...prev,
      [nodeId]: !prev[nodeId],
    }));
  };

  const handleCheck = (nodeId, subItems = []) => {
    const newChecked = { ...checked };
    const isCurrentlyChecked = checked[nodeId];

    setExpanded((prev) => ({
      ...prev,
      [nodeId]: true,
    }));
    newChecked[nodeId] = !isCurrentlyChecked;

    if (subItems.length > 0) {
      subItems.forEach((subItem) => {
        newChecked[subItem.title] = !isCurrentlyChecked;
      });
    }

    Object.keys(data).forEach((mainId) => {
      if (data[mainId].sub) {
        const allSubsChecked = data[mainId].sub.every(
          (sub) => newChecked[sub.title]
        );
        const someSubsChecked = data[mainId].sub.some(
          (sub) => newChecked[sub.title]
        );
        newChecked[mainId] = allSubsChecked;
        newChecked[`${mainId}-indeterminate`] =
          someSubsChecked && !allSubsChecked;
      }
    });

    handleCheckProp(newChecked);
    const checkedKeys = Object.keys(newChecked).filter(
      (key) => newChecked[key]
    );

    searchParams.set('filters', checkedKeys.join());

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      {sortedEntries.map(([nodeId, node]) => (
        <Box key={nodeId}>
          <ListItem
            sx={{
              cursor: 'pointer',
              '&:hover': { bgcolor: 'action.hover' },
              pl: '1.5rem',
              pr: 1,
              justifyContent: 'flex-start',
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                marginRight: '6px',
              }}
            >
              <GreenCheckbox
                edge='start'
                checked={checked[nodeId] || false}
                indeterminate={checked[`${nodeId}-indeterminate`] || false}
                onChange={() => handleCheck(nodeId, node.sub)}
                onClick={(e) => e.stopPropagation()}
              />
            </ListItemIcon>

            {categoryDetailsMapping[nodeId]?.icon &&
              categoryDetailsMapping[nodeId].icon}

            <ListItemText
              sx={{
                my: 0,
                marginLeft: '6px',
              }}
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    component='span'
                    sx={{
                      width: '172px',
                      fontSize: '.96rem',
                    }}
                  >
                    {node.main.details.title}
                  </Box>
                  {node.main.details.info && (
                    <Suspense fallback={<div>..</div>}>
                      <Tooltip name={node.main.details.title}>
                        {node.main.details.info}
                      </Tooltip>
                    </Suspense>
                  )}
                </Box>
              }
              onClick={() => handleToggle(nodeId)}
            />
            {node?.sub?.length > 0 ? (
              <IconButton
                onClick={() => handleToggle(nodeId)}
                size='small'
                sx={{
                  p: 0,
                  ml: 'auto',
                }}
              >
                {expanded[nodeId] ? <ArrowDownIcon /> : <ArrowIcon />}
              </IconButton>
            ) : null}
          </ListItem>

          {expanded[nodeId] && node.sub && (
            <List sx={{ pl: 3 }}>
              {node.sub.map((subItem) => (
                <ListItem
                  key={subItem.title}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { bgcolor: 'action.hover' },
                  }}
                >
                  <GreenCheckbox
                    edge='start'
                    checked={checked[subItem.title] || false}
                    size='small'
                    onChange={() => handleCheck(subItem.title)}
                    onClick={(e) => e.stopPropagation()}
                  />

                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          component='span'
                          sx={{
                            maxWidth: '172px',
                            marginTop: '4px',
                            marginX: '8px',
                            '&.MuiBox-root': {
                              fontSize: '.8rem',
                            },
                          }}
                        >
                          {subItem.title}
                        </Box>

                        {subItem.info ? (
                          <Suspense fallback={<div>..</div>}>
                            <Tooltip name={subItem.title}>
                              {subItem.info}
                            </Tooltip>
                          </Suspense>
                        ) : null}
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      ))}
    </List>
  );
}
