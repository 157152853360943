import { CloseSharp } from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import React, { Suspense } from 'react';
import { Colours } from '../theme';
import { MEDIA } from './Ui';
const Dialog = React.lazy(() => import('@mui/material/Dialog'));

const useStyles = makeStyles({
  root: {
    padding: '12px 20px',
    color: 'black',
  },
  openHelp: {
    color: 'var(--dark-green)',
    fontWeight: 600,
    cursor: 'pointer',
  },

  buttonContainer: {
    gap: '.5rem',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '8px',
  },

  primaryButton: {
    color: 'var(--dark-green)',
    fontWeight: 600,
    textDecoration: 'none',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '&:hover': {
      background: 'transparent',
    },
  },
  secondaryButton: {
    background: 'transparent',
    color: Colours._textGrey,
    fontWeight: 600,
    padding: '0',
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
    },
  },

  hideLabel: {
    color: '#999',
  },
});

const HEADER_HEIGHT = '96px';

export const HomePoll = (props) => {
  const classes = useStyles();
  const { pollPopupStatus, setPollPopupStatus } = props;
  const isMobile = useMediaQuery(MEDIA);

  const handleClose = () => {
    setPollPopupStatus(false);
  };

  const handleSelect = () => {
    window.localStorage.setItem('hidePoll', true);
    setPollPopupStatus(false);
  };

  const { root, hideLabel, buttonContainer, primaryButton, secondaryButton } =
    classes;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Dialog
        onClose={handleClose}
        open={pollPopupStatus}
        autoFocus={false}
        disableEnforceFocus
        sx={{
          maxWidth: '600px',
          left: 'unset',
          maxHeight: '280px',
          '.MuiDialog-container': {
            justifyContent: 'end',
            alignItems: 'start',
            maxHeight: 'fit-content',
            maxWidth: 'fit-content',
          },
          '.MuiPaper-root': {
            borderRadius: '12px',
            overflowX: 'hidden',
            maxWidth: '500px',
            overflowY: 'auto',
            marginTop: isMobile ? HEADER_HEIGHT : '24px',
          },
          '.MuiModal-backdrop': {
            left: '360px',
            backgroundColor: 'transparent',
            width: '0', //prevent backdrop from covering the map
          },
        }}
      >
        <DialogTitle className={root}>
          <Box
            display={'flex'}
            alignContent={'center'}
            justifyContent={'space-between'}
          >
            <Box
              my={0}
              p={0}
              display='flex'
              component='h2'
              fontWeight={600}
              sx={{
                fontSize: {
                  lg: 16,
                  sm: 16,
                  xs: 12,
                },
              }}
            >
              Schön, dass du da bist!
            </Box>
            <IconButton
              onClick={handleClose}
              size='small'
              sx={{
                padding: 0,
                ':hover': {
                  background: 'transparent',
                },
              }}
            >
              <CloseSharp />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box
          sx={{
            padding: '0 20px',
          }}
        >
          <Typography
            lineHeight={1.5}
            sx={{
              maxWidth: '95%',
              color: Colours._textGrey,
              fontWeight: 500,
              fontSize: {
                lg: 16,
                sm: 16,
                xs: 12,
              },
            }}
          >
            Wir würden uns freuen, wenn du uns einige Fragen zu deiner Erfahrung
            mit der Nutzung des MUT-ATLAS beantworten könntest. Du würdest uns
            damit sehr bei der Verbesserung des MUT-ATLAS unterstützen.
          </Typography>
          <Box display={'flex'} className={buttonContainer} mb='20px'>
            <Box display={'flex'} gap={1.5} alignItems='center'>
              <Button
                onClick={handleClose}
                tabIndex={0}
                className={secondaryButton}
              >
                Schließen
              </Button>
              <a
                href='https://survey.lamapoll.de/MUT-ATLAS'
                target='_blank'
                rel='noopener noreferrer'
                className={primaryButton}
                onClick={() => setPollPopupStatus(false)}
              >
                <Box component='span' mt={0.3}>
                  Zur Umfrage
                </Box>
                <ArrowForwardIosIcon
                  sx={{
                    fontSize: '16px',
                  }}
                />
              </a>
            </Box>

            <Button className={hideLabel} onClick={handleSelect}>
              Nicht mehr anzeigen
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Suspense>
  );
};
