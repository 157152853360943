import { useQueryClient } from '@tanstack/react-query';
import { CATEGORIES_KEY_MAP } from '../lib/constants';

export function useSearchPayload(actualChecked = {}) {
  const queryClient = useQueryClient();

  const data = queryClient.getQueryData(['filter-categories']);

  const createPayload = () => {
    if (!data?.allCategoriesGrouped) {
      return {};
    }

    const selectedFilterSearchData = {};
    const categoryData = data?.allCategoriesGrouped;

    Object.keys(categoryData).forEach((key) => {
      const translated_key = CATEGORIES_KEY_MAP[key];

      if (translated_key) {
        selectedFilterSearchData[translated_key] = categoryData[key]
          .filter((item) => {
            return actualChecked[item.wert];
          })
          .map((item) => item.wert);
      }
    });

    return selectedFilterSearchData;
  };

  return createPayload();
}
