import { Colours } from '../../theme';
import { Button, styled } from '@mui/material';

export const CustomButton = styled(Button)(() => ({
  color: Colours._darkGreen,
  backgroundColor: 'transparent',
  border: `1px solid ${Colours._darkGreen}`,
  '&:hover': {
    backgroundColor: Colours._darkGreen,
    color: 'white',
    '& .MuiBox-root, a': {
      display: 'block',
    },
  },
  '& .MuiBox-root, a': {
    marginRight: '4px',
    animation: `$slideIn 300ms ease-in`,
  },
}));
